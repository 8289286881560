import React, {useState, useEffect, useCallback, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faCheck, faSpinner} from "@fortawesome/free-solid-svg-icons";
import sendEmail from "../../scripts/email-sender/Email";
import Translator from "../../scripts/translator/Translator";
import UseTranslator from "../../scripts/translator/Translator";
import placeholderImage from "../../assets/media/unavailable.png";
import contactImage from '../../assets/media/contact.jpg';
import './style.css';
import {getModal} from "../../scripts/global";

const ProductCard = ({product, onClick, isSelected}) => {
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        import(`../../assets/media/${product.image}`)
            .then((module) => setPhoto(module.default))
            .catch((error) => {
                console.error(`Error loading image ${product.image}`, error);
                setPhoto(placeholderImage);
            });
    }, [product.image]);

    const requestOffer = UseTranslator({path: `button.requestOffer`});
    const [isActive, setIsActive] = useState(isSelected);

    const handleClick = () => {
        setIsActive(!isActive);
        onClick(product.title);
    };

    return (
        <div className="col p-3">
            <div data-id={product.id} className={`card ${isActive ? 'active' : ''}`} onClick={handleClick}>
                <div className={"tags"}>
                    <div className={"price"}>{product.price ?? requestOffer}</div>
                    <div
                        className={`brand ${!product.brand || product.brand.length === 0 ? "hidden" : ""}`}>{product.brand ?? ''}</div>
                </div>
                <header>
                    <img src={photo} alt="Product"/>
                </header>
                <div className={"content"}>
                    <div className={"title"}>{product.title}</div>
                    <div className={"description"}>{product.description}</div>
                    <div className="actions">
                        <button className="purchase-btn">
                              <span>
                                  <FontAwesomeIcon icon={isActive ? faCheck : faPlus}/>
                                  {isActive ? UseTranslator({path: `button.productAdded`}) : UseTranslator({path: `button.addToCart`})}
                              </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Index(props) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [emailData, setEmailData] = useState({sending: false});

    const formData = useRef();
    const products = UseTranslator({path: `section.shop-rent.list`});
    const [productsList, setProductsList] = useState(products);

    const handleClick = useCallback((name) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.includes(name)
                ? prevSelected.filter((item) => item !== name)
                : [...prevSelected, name]
        );
    }, []);

    const filterProducts = useCallback((e) => {
        const value = e.target.value.toLowerCase();
        if (e.key === "Enter" || !(e.target instanceof HTMLButtonElement)) {
            if (value.length === 0) {
                setProductsList(products);
            } else {
                setProductsList(products.filter((product) => product.title.toLowerCase().includes(value)));
            }
        }
    }, [products]);

    useEffect(() => document.querySelector(".basket")?.addEventListener("click", () => setModalVisible(true)), []);

    useEffect(() => {
        if (!modalVisible) {
            setEmailData({sending: false});
        }
    }, [modalVisible]);

    useEffect(() => {
        const basket = document.querySelector(".basket .tag");
        if (basket) {
            basket.innerText = selectedProducts.length;
        }
    }, [selectedProducts]);

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setEmailData({sending: true});
        sendEmail(formData.current, (response) => setEmailData({
            sending: true,
            status: response.status,
            message: response.message
        }));
    };

    const renderModalContent = () => (
        <div className="modal-content">
            <div className="modal-header" style={{backgroundImage: `url(${contactImage})`}}/>
            <div className="modal-body">
                <div className="title"><Translator path={"contact.title"}/></div>
                <div className="description card-white">
                    {!emailData.sending ? (
                        <form ref={formData} onSubmit={handleEmailSubmit}>
                            <div className={"row row-cols-2 gy-3 contact-form"}>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_name"}
                                           placeholder={UseTranslator({path: "contact.placeholder.name"})}/>
                                </div>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_email"}
                                           placeholder={UseTranslator({path: "contact.placeholder.email"})}/>
                                </div>
                                <div className={"col-12"}>
                                    <textarea name={"client_message"}
                                              placeholder={UseTranslator({path: "contact.placeholder.message"})}/>
                                </div>
                                {selectedProducts.length > 0 &&
                                    <div className={"col-12"}>
                                        <Translator path={"section.shop-rent.selectedProducts"}/>
                                        {selectedProducts.map(product => <li>{product}</li>)}
                                    </div>
                                }
                                <div className={"col-12"}>
                                    <input type={"hidden"} name={"client_selectedPackages"}
                                           defaultValue={selectedProducts.join(", ")}/>
                                    <button type={"submit"}><Translator path={"contact.send"}/></button>
                                </div>
                            </div>
                        </form>
                    ) : emailData.status == null ? (
                        <FontAwesomeIcon id={"loader"} icon={faSpinner}/>
                    ) : emailData.status === 200 ? (
                        <div className={"alert alert-success"}><Translator path={"contact.send.successfully"}/></div>
                    ) : (
                        <div className={"alert alert-danger"}>{emailData.message}</div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <section {...props}>
            <div className="row row-cols-1 justify-content-evenly p-5">
                <div className="col mb-5">
                    <h2 className="title text-center"><Translator path={"section.shop-rent.title"}/></h2>
                </div>
                <div className="col-3 pt-3">
                    <div className={"filters mx-auto"}>
                        <h2><Translator path={"section.shop-rent.search"}/></h2>
                        <hr/>
                        <input type="text" onKeyUp={filterProducts}/>
                    </div>
                </div>
                <div className="col-8 shop-rent">
                    <div className="row row-cols-1 row-cols-sm-2">
                        {productsList.map((product) => <ProductCard key={product.id} product={product}
                                                                    onClick={handleClick}
                                                                    isSelected={selectedProducts.includes(product.id)}/>)}
                    </div>
                </div>
            </div>
            {getModal({isOpen: modalVisible}, setModalVisible, renderModalContent())}
        </section>
    );
}
