import Translator from "./scripts/translator/Translator";
import {useEffect, useState} from "react";

import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Loading(props) {

    const [dots, setDots] = useState([]);
    const [maxDots] = useState(2);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prevDots => {
                if (dots.length < maxDots) {
                    return [...prevDots, "."];
                } else return [];
            });
        }, 500);

        return () => clearInterval(interval);
    }, [dots]);

    return (
        <section {...props}>
            <FontAwesomeIcon icon={faRotate} />
            <Translator path={"text.loading"} />{dots.map(dot => dot)}
        </section>
    )
}