import React, {useEffect, useState, useMemo} from "react";
import './style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Translator from "../../scripts/translator/Translator";
import UseTranslator from "../../scripts/translator/Translator";
import {getModal} from "../../scripts/global";

export default function Index(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [services, setServices] = useState([]);
    const [servicesPhotos, setServicesPhotos] = useState([]);

    const translatedServices = useMemo(() => UseTranslator({path: `section.services.list`}), []);

    useEffect(() => {
        const loadImages = async () => {
            const photos = await Promise.all(translatedServices.map((_, number) => {
                if (translatedServices[number].hidden) return null;
                return import(`../../assets/media/service${number}.jpg`).then(res => res.default);
            }));
            setServicesPhotos(photos.filter(photo => photo !== null));
        };

        const loadServices = () => {
            setServices(translatedServices);
        };

        loadImages();
        loadServices();
    }, [translatedServices]);

    const handleServiceClick = (index) => {
        setSelectedService(index);
        setModalVisible(true);
    };

    const renderServiceCards = () => {
        return services.map((item, index) => {
            if (item.hidden) return null;
            return (
                <div className="col" key={index}>
                    <div className="card clickable" data-id={index} onClick={() => handleServiceClick(index)}>
                        <div className='image py-5'>
                            <img src={servicesPhotos[index]} alt={`Service ${index}`}/>
                        </div>
                        <div className='text px-3'>
                            <div className='title'>{item.title}</div>
                            <div className='description'>{item.description}</div>
                        </div>
                        {item.fullDescription && (
                            <button className="clickable">
                                <FontAwesomeIcon icon={faArrowRight} className={"me-2"}/>
                                {UseTranslator({path: "button.readMore"})}
                            </button>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <section {...props}>
            <div className="row row-cols-2 justify-content-center p-5 h-100">
                <div className="col-12 mb-5">
                    <h2 className="title text-center"><Translator path="section.services.title"/></h2>
                </div>
                <div className="col-12 col-md-10">
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 justify-content-center services">
                        {renderServiceCards()}
                    </div>
                </div>
            </div>
            {getModal({isOpen: modalVisible, contentLabel: selectedService},
                setModalVisible,
                selectedService !== null && (
                    <div className="modal-content">
                        <div className="modal-header" style={{backgroundImage: `url(${servicesPhotos[selectedService]})`}}/>
                        <div className="modal-body">
                            <div className="title">{services[selectedService]?.title}</div>
                            <div
                                className="description card-white"
                                dangerouslySetInnerHTML={{__html: services[selectedService]?.fullDescription}}
                            />
                        </div>
                    </div>
                )
            )}
        </section>
    );
}
