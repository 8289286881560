import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook} from '@fortawesome/free-brands-svg-icons';

import Menu from "../Menu";
import Translator from "../../scripts/translator/Translator";

import './style.css';

const contactButtons = [
    {icon: faPhone, action: () => window.location = "tel:0745173933"},
    {
        icon: faFacebook,
        action: () => window.open("https://www.facebook.com/AxaServiceSRL/", "_blank"),
        className: "ms-3"
    },
    {icon: faEnvelope, action: () => window.location = "mailto:office@axaservice.ro", className: "ms-3"}
];

function Index(props) {
    return (
        <section {...props}>
            <div className="row row-cols-1">
                <div className="col">
                    <Menu toAnchor={props.toAnchor}/>
                </div>
            </div>
            <div className="row row-cols-1 justify-content-center min-vh-100">
                <div className="col align-self-end text-center mb-5">
                    <h1><Translator path="section.home.title"/></h1>
                    <h2><Translator path="section.home.subTitle"/></h2>
                </div>
                <div className="col-auto">
                    <div className="row row-cols-3 justify-content-center">
                        {contactButtons.map(({icon, action, className = ""}, index) => (
                            <button
                                key={index}
                                className={`icon clickable ${className}`}
                                onClick={action}
                            >
                                <FontAwesomeIcon icon={icon}/>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Index;