import React, {useEffect, useState, useMemo, useRef} from "react";
import './style.css';
import Translator from "../../scripts/translator/Translator";
import UseTranslator from "../../scripts/translator/Translator";
import {getModal} from "../../scripts/global";
import sendEmail from "../../scripts/email-sender/Email";
import contactImage from '../../assets/media/contact.jpg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Index = (props) => {
    const [fabrications, setFabrications] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [emailData, setEmailData] = useState({sending: false});
    const [selectedOffer, setSelectedOffer] = useState('');
    const formData = useRef();

    const translation = useMemo(() => UseTranslator({path: "section.metal-fabrications.list"}), []);

    useEffect(() => {
        const loadFabrications = async () => {
            const loadedFabrications = await Promise.all(translation.map(async (item, index) => {
                const {default: photo} = await import(`../../assets/media/fabrication${index}.jpg`);
                return {...item, photo};
            }));
            setFabrications(loadedFabrications);
        };

        loadFabrications();
    }, [translation]);

    useEffect(() => {
        if (!modalVisible) {
            setEmailData({sending: false});
        }
    }, [modalVisible]);

    const handleCardClick = (itemTitle) => {
        setSelectedOffer(itemTitle);
        setModalVisible(true);
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setEmailData({sending: true});
        sendEmail(formData.current, (response) => setEmailData({
            sending: true,
            status: response.status,
            message: response.message
        }));
    };

    const renderCards = () => (
        fabrications.map((item, index) => {
            const isSecondCardInRow = (index % 3) === 1;  // Check if it's the second card in a 3-column row

            return (
                <div key={index} className={`col ${isSecondCardInRow ? 'pt-5' : ''}`}>
                    <div className="card clickable">
                        <div className="card-photo">
                            <img src={item.photo} alt={`Picture of fabrication ${index + 1}`}/>
                        </div>
                        <div className="card-body">
                            <div className="card-title">{item.title}</div>
                            <div className="card-text">{item.description}</div>
                            <button className="card-button mt-auto" onClick={() => handleCardClick(item.title)}>
                                {UseTranslator({path: "button.requestOffer"})}
                            </button>
                        </div>
                    </div>
                </div>
            );
        })
    );

    const renderModalContent = () => (
        <div className="modal-content">
            <div className="modal-header" style={{backgroundImage: `url(${contactImage})`}}/>
            <div className="modal-body">
                <div className="title"><Translator path={"contact.title"}/> - {selectedOffer}</div>
                <div className="description card-white">
                    {!emailData.sending ? (
                        <form ref={formData} onSubmit={handleEmailSubmit}>
                            <div className={"row row-cols-2 gy-3 contact-form"}>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_name"}
                                           placeholder={UseTranslator({path: "contact.placeholder.name"})}/>
                                </div>
                                <div className={"col"}>
                                    <input type={"text"} name={"client_email"}
                                           placeholder={UseTranslator({path: "contact.placeholder.email"})}/>
                                </div>
                                <div className={"col-12"}>
                                    <textarea name={"client_message"}
                                              placeholder={UseTranslator({path: "contact.placeholder.message"})}/>
                                </div>
                                <div className={"col-12"}>
                                    <input type={"hidden"} name={"client_selectedPackages"}
                                           defaultValue={selectedOffer}/>
                                    <button type={"submit"}><Translator path={"contact.send"}/></button>
                                </div>
                            </div>
                        </form>
                    ) : emailData.status == null ? (
                        <FontAwesomeIcon id={"loader"} icon={faSpinner}/>
                    ) : emailData.status === 200 ? (
                        <div className={"alert alert-success"}><Translator path={"contact.send.successfully"}/></div>
                    ) : (
                        <div className={"alert alert-danger"}>{emailData.message}</div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <section {...props}>
            <div className="row row-cols-2 justify-content-center p-5 h-100">
                <div className="col-12 mb-5">
                    <h2 className="title text-center">
                        <Translator path="section.metal-fabrications.title"/>
                    </h2>
                </div>
                <div className="col-12 col-md-10">
                    <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 gy-5 fabrications">
                        {renderCards()}
                    </div>
                </div>
            </div>
            {getModal({isOpen: modalVisible}, setModalVisible, renderModalContent())}
        </section>
    );
};

export default Index;
