import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope, faBasketShopping, faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import Translator from '../scripts/translator/Translator';
import logo from '../assets/media/logo.png';

import config from '../config.json'

const menus = [
    { anchor: "home", type: "button" },
    { anchor: "aboutUs", type: "button" },
    { anchor: "services", type: "button" },
    { anchor: "metal-fabrications", type: "button" },
    { anchor: "shop-rent", type: "button" },
    // { anchor: "careers", type: "button" },
    // { anchor: "contact ms-auto", type: "icon", icon: faEnvelope, clickable: true },
    { anchor: "basket", type: "icon", icon: faBasketShopping, clickable: true }
];

export default function Menu(props) {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const onClick = (e) => {
        const anchor = e.currentTarget.getAttribute("data-anchor");
        props.toAnchor(anchor);
        if (config.isMobile) setMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

    return (
        <nav className="row align-items-center menu main sticky">
            <div className="col-auto logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="col-2 ms-auto mobile-menu-toggle">
                <button onClick={toggleMobileMenu}>
                        <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            <div className={"col items " + (isMobileMenuOpen ? '' : config.isMobile ? 'hidden' : '')}>
                <div className={"row row-cols-auto row-gap-3 justify-content-center"}>
                    {isMobileMenuOpen && (
                        <button onClick={toggleMobileMenu} className={"close"}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    )}
                    {menus.map(({ anchor, type, icon, clickable }) => {
                        switch (type) {
                            case "button": return (
                                <button key={anchor} className={config.isMobile && isMobileMenuOpen ? "col-6" : "col-auto"} onClick={onClick} data-anchor={anchor}>
                                    <Translator path={`menu.${anchor}`} />
                                    <div className="selector" />
                                </button>
                            );

                            case "icon": {
                                if (config.isMobile) return (
                                    <div className={"col-6"}>
                                        <button key={anchor} className={`icon ${clickable ? "clickable" : ""} ${anchor}`} onClick={onClick} data-anchor={anchor}>
                                            <FontAwesomeIcon icon={icon} /> {anchor === "basket" && <div className={"tag"}>0</div>}
                                        </button>
                                    </div>
                                )
                                else return (
                                    <button key={anchor} className={`icon ${clickable ? "clickable" : ""} ${anchor}`} onClick={onClick} data-anchor={anchor}>
                                        <FontAwesomeIcon icon={icon} /> {anchor === "basket" && <div className={"tag"}>0</div>}
                                    </button>
                                )
                            }
                        }
                    })}
                </div>
            </div>
        </nav>
    );
}