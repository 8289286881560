import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

const getRandomNumber = (min, max) => parseInt(Math.random() * (max - min) + min);
const getModal = (props, setModalVisible, content) => {

    const closeModal = () => {
        document.querySelector(".ReactModal__Overlay").classList.remove("ReactModal__Overlay--after-open");
        setTimeout(() => setModalVisible(false), 100);
    }

    return (
        <Modal {...props} onRequestClose={closeModal} ariaHideApp={false}>
            <button className="close" onClick={closeModal}><FontAwesomeIcon icon={faClose}/></button>
            {content}
        </Modal>
    );
}

export {
    getRandomNumber,
    getModal
}