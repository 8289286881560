import React, {useEffect, useState, useCallback} from "react";
import Home from "./entities/home";
import AboutUs from "./entities/abous-us";
import Services from "./entities/services";
import MetalFabrications from "./entities/metal-fabrications";
import ShopRent from './entities/shop-rent';
import {getRandomNumber} from "./scripts/global";
import config from './config.json';
import bg1 from './assets/media/bg1.jpg';
import bg2 from './assets/media/bg2.jpg';
import bg3 from './assets/media/bg3.jpg';
import bg4 from './assets/media/bg4.jpg';
import Loading from "./loading";
import Maintenance from "./maintenance";

const backgroundImages = [bg1, bg2, bg3, bg4];

function App() {
    const [isLoading, setIsLoading] = useState(false);
    const [isMaintenance, setMaintenance] = useState(false);

    const getRandomBackgroundImage = useCallback(() => {
        const randomIndex = getRandomNumber(0, backgroundImages.length - 1);
        return `url(${backgroundImages[randomIndex]})`;
    }, []);

    useEffect(() => {
        config.isMobile = navigator.userAgentData?.mobile;
        setMaintenance(config.maintenance.desktop || (config.isMobile && config.maintenance.mobile));

        if (isLoading) return;

        const sections = document.querySelectorAll("section");
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show");
                    updateSelectorMenu(entry.target.id);
                }
            });
        }, {threshold: 0.1});

        sections.forEach((section, index) => {
            observer.observe(section);
            if (index % 2 === 0) section.style.backgroundImage = getRandomBackgroundImage();
        });
    }, [isLoading, getRandomBackgroundImage]);

    const updateSelectorMenu = (id) => {
        const menuButtons = document.querySelectorAll(".menu.main button");
        menuButtons.forEach(menuButton => {
            const selector = menuButton.querySelector(".selector");

            if (!selector) return true;

            const isSelected = menuButton.getAttribute("data-anchor") === id;
            selector.classList.toggle("selected", isSelected);
        });
    };

    const toAnchor = (anchor) => {
        window.location.href = `${window.location.pathname}#${anchor}`;
    };

    return (
        <div className="app">
            {isMaintenance && <Maintenance className="maintenance"/>}
            {isLoading && !isMaintenance && <Loading className="loading"/>}
            {!isLoading && !isMaintenance && (
                <>
                    <Home id="home" className="home show" toAnchor={toAnchor}/>
                    <AboutUs id="aboutUs"/>
                    <Services id="services"/>
                    <MetalFabrications id="metal-fabrications"/>
                    <ShopRent id="shop-rent"/>
                </>
            )}
        </div>
    );
}

export default App;