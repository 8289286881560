import Translator from "./scripts/translator/Translator";

import {faHammer} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Maintenance(props) {


    return (
        <section {...props}>
            <div className="row row-cols-1 justify-content-center opacity-100">
                <div className={"col text-center opacity-100"}>
                    <FontAwesomeIcon icon={faHammer} />
                </div>
                <div className="col mt-5 text-center opacity-100">
                    <Translator path={"text.maintenance"} />
                </div>
            </div>
        </section>
    )
}